import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects';
import { APIParams } from 'helpers/Utils';
import {
  BALANCE_SHARING_LIST_REQUEST,
  listBalanceSharingSuccess,
  listBalanceSharingError,
} from './actions';

import { getCurrentOrganization } from '../organizations/selectors';

function* listBalanceSharing(api, { payload }) {
  const currentOrganization = yield select(getCurrentOrganization);
  const response = yield call(
    api.balanceSharing,
    APIParams({ ...payload.params, organizationId: currentOrganization.id })
  );

  if (response.ok) {
    const list = response.data.docs;
    const { current: currentPage, total: totalPages } = response.data.pages;
    const { total: totalItems, skip: skipItems = 0 } = response.data.items;
    const pages = { currentPage: Number(currentPage), totalPages };
    const items = { totalItems, skipItems };

    yield put(listBalanceSharingSuccess({ list, pages, items }));
  } else {
    const message = 'Não foi possível carregar estes dados';

    yield put(listBalanceSharingError({ message }));
  }
}

export function* watchListBalanceSharing(api) {
  yield takeEvery(BALANCE_SHARING_LIST_REQUEST, listBalanceSharing, api);
}

export default function* rootSaga(api) {
  yield all([fork(watchListBalanceSharing, api)]);
}
